import Layout from "../components/layout";
import React from "react";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>This page doesn't exist</h1>
    <p>The fuck you think you're doing? Trying to find hidden pages? Go back to where you came from, trespasser.</p>
  </Layout>
);

export default NotFoundPage;
